import utils from "./utils";
import stateManager from './stateManager'
import _ from "lodash";
import {questionsMeta, structure} from "./structure";
import axios from "axios";
import {disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import QRCode from "qrcode";
import Inputmask from "inputmask";
import FileSaver from 'file-saver';
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';

let results = function () {
    const $page = $('.page-results');

    $page.find('.legal_year').html(new Date().getFullYear());
    $page.find('.partner_logo').attr('src', '/assets/logo_' + process.env.SOURCE + '.svg');
    $page.find('.section.cover').css('background-image', 'url("/assets/cover_' + process.env.SOURCE + '.jpg")');
    $page.find('.section.cover img').attr('src', '/assets/cover_logo_' + process.env.SOURCE + '_white.svg');

    const uuid = utils.getFromLS('uuid');
    const $firstName = $page.find('.field.first_name input');
    const $lastName = $page.find('.field.last_name input');
    const $phone = $page.find('.field.phone input');

    let isSubmitActive = true;

    let allResults = false;


    const $buttonBuy = $page.find('.button.purchase');
    const $promocodeInput = $('#order-promocode');
    const $promocodeStatus = $('.statusPromocode');
    const $promocodeTrigger = $promocodeInput.parent().find('.action');

    const $productName = $('.line-name .name');
    const $productPrice = $('.line-name .price');
    const $oldPrice = $('.oldprice');
    const $totalPrice = $('.total .price');
    const $linePromo = $('.line-promo');

    let activeProduct = 1;
    let activePromocode = '';

    let productsData = {};


    ////
    // request test results from server
    ////
    axios.post(process.env.API_URL + 'api/results/calculate', {
        uuid: uuid
    })
        .then(function (response) {
            response = response.data;

            if(response.status){



                if(!response.data.isPaid){
                    console.log('not paid');

                    $page.find('.results').remove();
                    $page.find('.section.state').remove();
                    $page.find('.section.promo').remove();
                    $page.find('.locked').addClass('active');


                    axios.get(process.env.API_URL + 'api/prices/get')
                        .then(function (res) {
                            res = res.data;
                            if(res.status){
                                productsData = res.data;

                                processAnswer(response.data.results, false);
                                $page.find('.block.loading').removeClass('active');

                            }else{
                                console.log('Error getting data from server');
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                }else{
                    processAnswer(response.data.results, true);

                    $page.find('.locked').remove();
                    $page.find('.block.loading').removeClass('active');
                }
            }else{
                utils.notifyError(response.message);
            }
        })
        .catch(function (error) {
            console.log(error);
        });

    const processAnswer = function (results, showResults) {

        $page.find('.section.top .descr').html(results.summary.short);
        $page.find('.section.top .scale').addClass(results.summary.level);
        $page.find('.section.top .scale .line').css('width', Math.max(10, (100 / results.summary.points * results.summary.score)) + '%');
        $page.find('.section.top .graph .tag').html(results.summary.level_tag);
        $page.find('.section.top .graph img').attr('src', '/assets/state_'+results.summary.level+'.png');
        $page.find('.section.top .graph').addClass(results.summary.level);
        $page.find('.section.top .graph path:last-child').css('stroke-dashoffset', 484 - (484 / 100 * Math.max(10, (100 / results.summary.points * results.summary.score))));

        if(showResults) {
            const $results = $page.find('.results');

            allResults = results;

            let table = _.sortBy(results.table, function (result) {
                return 100 / result.points * result.score;
            });

            _.each(table, function (result, categoryId) {

                let resultElem = '<div id="' + result.categoryId + '" class="section result ' + result.categoryId + '">\n' +
                    '                <div class="left">\n' +
                    '                    <div class="trigger trigger-icon">\n' +
                    '                        <img src="/assets/' + result.categoryId + '.svg">\n' +
                    '                    </div>\n' +
                    '                    <div class="title">' + result.category + '</div>\n' +
                    '                </div>\n' +
                    '                <div class="right">\n' +
                    '                    <div class="bar ' + result.level + '">\n' +
                    '                        <div class="info">\n' +
                    '                            <div class="tag">' + result.short + '</div>\n' +
                    '                        </div>\n' +
                    '                        <div class="scale">\n' +
                    '                            <div class="line" style="width: ' + Math.max(10, (100 / result.points * result.score)) + '%;"><div></div></div>\n' +
                    '                        </div>\n' +
                    '                    </div>\n' +
                    '                </div>\n' +
                    '            </div>\n' +
                    '        </div>'

                $results.append($(resultElem));

            });

            $page.find('.section.state .descr').html(results.state.short);
            $page.find('.section.state .scale').addClass(results.state.level);
            $page.find('.section.state .scale .line').css('width', Math.max(10, (100 / results.state.points * results.state.score)) + '%');
            $page.find('.section.state .graph .tag').html(results.state.level_tag);
            $page.find('.section.state .graph img').attr('src', '/assets/state_'+results.state.level+'.png');
            $page.find('.section.state .graph').addClass(results.state.level);
            $page.find('.section.state .graph path:last-child').css('stroke-dashoffset', 484 - (484 / 100 * Math.max(10, (100 / results.state.points * results.state.score))));
            if(results.state.score === 0){
                $page.find('.section.state .info .button').remove();
                $page.find('.section.state .graph').remove();
                $page.find('.section.state .scale').remove();
            }
        }

    };


    const closeModal = function (modal) {
        const $modal = $('.block.' + modal);
        $modal.removeClass('active');
        enableBodyScroll($modal[0]);
    };
    const activateCloseListeners = function (modal) {
        const $modalElem = $page.find('.block.' + modal + ' .wrapper');
        $modalElem.on('click', function (e) {
            if (e.target !== e.currentTarget) return;
            // console.log('clicked outside modal frame');
            closeModal(modal);
        });
        $modalElem.find('.close').on('click', function (e) {
            // console.log('clicked close button');
            closeModal(modal);
        });
    };

    activateCloseListeners('detailsState');
    activateCloseListeners('details');
    activateCloseListeners('contact');
    activateCloseListeners('qr');
    activateCloseListeners('order');

    $page.find('.button.detailsState').on('click', function () {
        const $modal = $page.find('.block.detailsState');
        disableBodyScroll($modal[0]);
        // const elemId = $(this).attr('id');
        $modal.find('.descr').html(allResults.state.description);
        $modal.addClass('active');
    });

    $page.on('click', '.section.result', function () {
        const $modal = $page.find('.block.details');
        disableBodyScroll($modal[0]);
        const elemId = $(this).attr('id');
        const entry = _.find(allResults.table, { 'categoryId': elemId });
        $modal.find('h3').html(entry.category);
        $modal.find('.descr').html(entry.description);
        $modal.addClass('active');
    });

    $page.find('.button.contact').on('click', function () {
        const $modal = $page.find('.block.contact');
        disableBodyScroll($modal[0]);
        $modal.addClass('active');
    });



    //PDF generation
    $page.find('.button.pdf').on('click', function () {
        axios.post(process.env.API_URL + 'api/results/pdf', {
                uuid: uuid,
                url: document.location.origin + '/template/pdf-results'
            }, {
                responseType: "arraybuffer"
            })
            .then(function (response) {
                const blob = new Blob([response.data], {type: "application/pdf;charset=utf-8"});
                FileSaver.saveAs(blob, "results.pdf");
            })
            .catch(function (error) {
                console.log(error);
            });
    });


    //WhatsApp's integration
    const openWhatsappChat = function (link) {
        window.open(link);
    };
    if (process.env.WHATSAPP_BUTTON === 'true') {
        const link = 'https://wa.me/' + process.env.WHATSAPP_CHAT_PHONE + '?text=' + (encodeURIComponent(process.env.WHATSAPP_CHAT_TEXT));

        //on mobile phones launch WhatsApp right away
        $page.find('.button.whatsapp').on('click', function () {
            openWhatsappChat(link);
        });

        //on desktop & tables show modal window with QR-code
        $page.find('.button.qr').on('click', function () {
            const $modal = $page.find('.block.qr');
            disableBodyScroll($modal[0]);
            $modal.addClass('active');
        });

        QRCode.toCanvas($page.find('#qrcode')[0], link, {color: {dark: '#4095F2FF'}}, function (error) {
            if (error) console.error(error)
        })

        $page.find('.button.launch').on('click', function () {
            openWhatsappChat(link);
        });
    }


    const phoneMaskFormat = '+7 (999) 999-9999';
    Inputmask({
        mask: phoneMaskFormat,
        showMaskOnHover: false,
        clearMaskOnLostFocus: false,
        onKeyValidation: function () {
        },
        onBeforePaste: function (pastedValue, opts) {
            if (Array.from(pastedValue)[0] === '8') {
                return pastedValue.substring(1);
            } else if (Array.from(pastedValue)[0] === '+') {
                return pastedValue.substring(2);
            } else {
                return pastedValue;
            }
        }
    }).mask($phone[0]);

    $firstName.on('input', function () {
        if ($(this).val()) {
            $(this).parent().find('.placeholder').removeClass('active');
        } else {
            $(this).parent().find('.placeholder').addClass('active');
        }
    });

    $lastName.on('input', function () {
        if ($(this).val()) {
            $(this).parent().find('.placeholder').removeClass('active');
        } else {
            $(this).parent().find('.placeholder').addClass('active');
        }
    });

    const processForm = function () {
        if (isSubmitActive) {
            $firstName.parent().removeClass('error');
            $lastName.parent().removeClass('error');
            $phone.parent().removeClass('error');

            const isValidFirstName = $firstName.val().length > 0;
            const isValidLastName = $lastName.val().length > 0;
            const isValidPhone = Inputmask.isValid($phone.val(), {alias: phoneMaskFormat});

            if (!isValidFirstName) {
                $firstName.parent().addClass('error');
            }
            if (!isValidLastName) {
                $lastName.parent().addClass('error');
            }
            if (!isValidPhone) {
                $phone.parent().addClass('error');
            }

            const isValidForm = isValidFirstName && isValidLastName && isValidPhone;

            if (isValidForm) {
                isSubmitActive = false;

                const user = {
                    uuid: uuid,
                    firstName: $firstName.val(),
                    lastName: $lastName.val(),
                    phone: $phone.val()
                };

                // console.log(user);

                axios.post(process.env.API_URL + 'api/user/update', {
                    user: user
                })
                    .then(function (response) {
                        response = response.data;

                        if(response.status){
                            $page.find('.contact .main').removeClass('active');
                            $page.find('.contact .success').addClass('active');
                        }else{
                            utils.showNotification(response.message);
                            isSubmitActive = false;
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    };

    $page.find('.contact .submit').on('click', processForm);



    ///////////
    // ORDER //
    ///////////

    const format = function (number) {
        return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ₽';
    }

    const resetModal = function (){

        // console.log(activeProduct);
        // console.log(productsData[activeProduct]);
        activePromocode = '';
        $oldPrice.removeClass('active');
        $linePromo.removeClass('active');
        $promocodeInput.val('');
        $promocodeInput.prop('disabled', false);
        $promocodeTrigger.removeClass('filled').removeClass('active');
        $promocodeStatus.removeClass('active').removeClass('success');
        $totalPrice.text(format(productsData[activeProduct].price));
        $productName.text(productsData[activeProduct].name);
        $productPrice.text(format(productsData[activeProduct].price));
        $oldPrice.text(format(productsData[activeProduct].price));

    };

    $page.find('.button.checkout').on('click', function () {
        activeProduct = $(this).attr('id').split('-')[1];
        const $modal = $page.find('.block.order');
        disableBodyScroll($modal[0]);
        resetModal();
        $modal.addClass('active');
    });

    $promocodeInput.on('input', function(){
        if($(this).val() === ''){
            $promocodeStatus.removeClass('active')
        }
    });
    $promocodeInput.on('focus', function(){
        $promocodeTrigger.addClass('active');
    }).on('blur', function(){
        if($promocodeInput.val() === '') {
            console.log('blur');
            $promocodeTrigger.removeClass('active');
        }
    });

    $promocodeTrigger.on('click', function(){
        if(!$(this).hasClass('filled')){
            if($promocodeInput.val() !== ''){
                console.log('proceed recalculate');
                recalculate($promocodeInput.val());
            }
        }else{
            console.log('proceed remove');
            resetModal();
        }
    });


    let isPromoActive = true;
    const recalculate = function(promocode){
        if(isPromoActive) {
            isPromoActive = false;

            $promocodeStatus.removeClass('active').removeClass('success');

            axios.post(process.env.API_URL + 'api/price/recalculate', {
                product: activeProduct,
                promocode: promocode
            })
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        // const data = {
                        //     price: 10990,
                        //     discount: -1099.00,
                        //     total: 9891.00,
                        //     promoStatus: true,
                        //     promocode: 'PODAROK2024'
                        // }
                        const data = response.data;

                        if (data.promoStatus) {
                            $promocodeInput.prop('disabled', true);
                            $oldPrice.addClass('active');
                            $linePromo.find('.discount').text(format(data.discount));
                            $linePromo.addClass('active');
                            $promocodeTrigger.addClass('filled');
                            $promocodeStatus.text('Промокод активирован').addClass('success active');
                            $totalPrice.text(format(data.total));
                            activePromocode = data.promocode;
                        } else {
                            $promocodeStatus.text('Промокод не найден').addClass('active');
                        }
                    }else{
                        $promocodeStatus.text('Промокод не найден').addClass('active');
                    }
                })
                .then(function(){
                    isPromoActive = true;
                })
                .catch(function (error) {
                    $promocodeStatus.text('Нет соединения с сервером').addClass('active');
                    console.log(error);
                });
        }

    };


    $buttonBuy.on('click', function(){
        if(!$(this).hasClass('disabled')){
            purchase();
        }
    });


    let isButtonActive = true;
    const purchase = function () {
        if (isButtonActive) {
            isButtonActive = false;

            $buttonBuy.addClass('disabled');
            $promocodeStatus.removeClass('active');

            const referer = window.location.search.substring(1);

            const data = {
                product: activeProduct,
                promocode: activePromocode,
                test: uuid,
                referral: referer
            };

            // console.log(data);

            axios.post(process.env.API_URL + 'api/payment/create', data)
                .then(function (response) {
                    response = response.data;
                    if(response.status){
                        window.location.href = response.data.paymentURL;
                    }else{
                        // console.log(response.message);
                        let errorMessage = response.message;
                        $promocodeStatus.html(errorMessage).addClass('active');
                        isButtonActive = true;
                        $buttonBuy.removeClass('disabled');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    isButtonActive = true;
                    $buttonBuy.removeClass('disabled');
                });

        }
    };


};

export default results;