import utils from "./utils"

import stateManager from "./stateManager";
// import {question_0_1} from "./questions/question_0_1";
import {task_1_1} from "./questions/task_1_1";
import {task_2_1} from "./questions/task_2_1";
import {task_2_2} from "./questions/task_2_2";
import {task_3_1} from "./questions/task_3_1";
import {task_4_1} from "./questions/task_4_1";
import {task_5_1} from "./questions/task_5_1";
import {task_6_1} from "./questions/task_6_1";
import {task_7_1} from "./questions/task_7_1";
import {task_8_1} from "./questions/task_8_1";
import {task_9_1} from "./questions/task_9_1";
import _ from "lodash";
import {structure, questionsMeta} from "./structure";
import stt from "./stt";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from "body-scroll-lock";
import axios from "axios";
import {Howl, Howler} from 'howler';
import Bowser from 'bowser';
import Inputmask from "inputmask";

const questionsScripts = {
    // "question/0/1": question_0_1,
    "question/1/1": task_1_1,
    "question/2/1": task_2_1,
    "question/2/2": task_2_2,
    "question/3/1": task_3_1,
    "question/4/1": task_4_1,
    "question/5/1": task_5_1,
    "question/6/1": task_6_1,
    "question/7/1": task_7_1,
    "question/8/1": task_8_1,
    "question/9/1": task_9_1,
}

let questions = function (params, isNewApp, SM) {

    //console.log('LOADING QUESTION');

    const $page = $('.page-question');
    const $phone = $page.find('.field.phone input');
    const $phoneNumber = $page.find('.stage.two .number');
    const $phoneStatus = $page.find('.stage.one .status');
    const $code = $page.find('.field.code input');
    const $codeStatus = $page.find('.stage.two .status');
    const $repeatMessage = $page.find('.stage.two .repeatAfter');
    const $repeatButton = $page.find('.stage.two .repeat');
    const $auth = $page.find('.auth');
    const $submitRequestCode = $auth.find('.button.request');
    let isSubmitActive = true;

    const pagesDir = '/pages/questions/';
    const currentState = utils.getCurrentState();
    const meta = questionsMeta[currentState];

    let phoneToVerify = false;
    let timeTillRepeatCode = 0;



    // set filters for inputs
    const phoneMaskFormat = '+7 (999) 999-9999';
    Inputmask({
        mask: phoneMaskFormat,
        showMaskOnHover: false,
        clearMaskOnLostFocus: true,
        onKeyValidation: function () {
        },
        onBeforePaste: function (pastedValue, opts) {
            if (Array.from(pastedValue)[0] === '8') {
                return pastedValue.substring(1);
            } else if (Array.from(pastedValue)[0] === '+') {
                return pastedValue.substring(2);
            } else {
                return pastedValue;
            }
        }
    }).mask($phone[0]);


    const session = utils.getFromLS('uuid');
    if(session !== null && session){
        $page.find('.intro .session').addClass('active');
    }else{
        $page.find('.intro .auth').addClass('active');
    }


    const repeatTimer = async function(secondsLeft){
        if(secondsLeft > 0) {
            $repeatMessage.find('span').text(secondsLeft);
            setTimeout(function(){
                timeTillRepeatCode--;
                repeatTimer(timeTillRepeatCode)
            }, 1000);
        }else{
            $repeatMessage.removeClass('active');
            $repeatButton.addClass('active');
        }

    };

    const processCodeRequest = function (e, isRepeat = false) {
        if (isSubmitActive) {
            $phone.parent().removeClass('error');
            $phoneStatus.text('').removeClass('active');
            phoneToVerify = false;

            const isValidPhone = Inputmask.isValid($phone.val(), {alias: phoneMaskFormat});
            if (!isValidPhone) {
                $phone.parent().addClass('error');
            }

            if (isValidPhone && timeTillRepeatCode === 0) {
                isSubmitActive = false;

                phoneToVerify = $phone.val();

                axios.post(process.env.API_URL + 'api/auth/code/request', {
                    phone: phoneToVerify
                })
                    .then(function (response) {
                        response = response.data;

                        console.log(response);

                        if(response.status){

                            if(!isRepeat){
                                $phoneNumber.find('span').text(phoneToVerify);
                                $auth.find('.stage.one').removeClass('active');
                                $auth.find('.stage.two').addClass('active');
                            }
                            $repeatMessage.addClass('active');
                            $repeatButton.removeClass('active');

                            timeTillRepeatCode = 40;
                            repeatTimer(timeTillRepeatCode);

                        }else{
                            $phoneStatus.text(response.message).addClass('active');
                        }
                        isSubmitActive = true;

                    })
                    .catch(function (error) {
                        console.log(error);
                        isSubmitActive = true;
                    });
            }
        }
    };

    const processCodeCheck = async function (code) {
        if (isSubmitActive) {
            $code.parent().removeClass('error');
            $codeStatus.text('').removeClass('active');
            $code.attr("disabled", true);

            let isValidCode = false;

            if(code.length === 4){
                isValidCode = true
            }

            if(isValidCode){
                isSubmitActive = false;

                isSubmitActive = true;
                $code.attr("disabled", false);

                axios.post(process.env.API_URL + 'api/auth/code/confirm', {
                    phone: phoneToVerify,
                    code: code,
                })
                    .then(function (response) {
                        response = response.data;

                        console.log(response);

                        if(response.status){
                            const test = response.data;

                            utils.clearLS();
                            utils.setToLS('uuid', test.testUUID);
                            utils.setCurrentState(currentState);
                            utils.setToLS('termsAccepted', true);
                            structure.questions = test.structure;

                            if(!test.isFinished){
                                const $modal = $page.find('.block.intro');
                                const currentIndex = $modal.find('.state.active').index();
                                console.log(currentIndex);
                                $modal.find('.state.active').removeClass('active');
                                $modal.find('.state').eq(currentIndex+1).addClass('active');
                                $modal.find('.bullets span').removeClass('active').eq(currentIndex+1).addClass('active');
                            }else{
                                console.log('test is finished, redirect to RESULTS');
                                clearAllBodyScrollLocks();
                                SM.goToFinalState();
                            }

                        }else{
                            $codeStatus.addClass('active').text('Неверный код подтверждения');
                            isSubmitActive = true;
                        }

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    };

    $submitRequestCode.on('click', processCodeRequest);
    $repeatButton.on('click', (e) => processCodeRequest(e, true));

    $phone.on('keydown', function(e){
        if(e.keyCode === 13){
            processCodeRequest();
        }
    });

    $phone.on('focus', function () {
        $(this).parent().find('.placeholder').removeClass('active');
    });
    $phone.on('blur', function () {
        if (!$(this).val()) {
            $(this).parent().find('.placeholder').addClass('active');
        }
    });

    $code.on('input', function () {
        if ($(this).val()) {
            $(this).parent().find('.placeholder').removeClass('active');
            if($(this).val().length === 4){
                console.log('Check code');
                processCodeCheck($(this).val());
            }
        } else {
            $(this).parent().find('.placeholder').addClass('active');
        }
    });



    const toggleBlock = function (blockName) {
        $page.find('.block').removeClass('active');
        $page.find('.block.' + blockName).addClass('active');
    };

    /////
    // set 100% window height
    /////
    const isMobile = utils.isMobileAndTablet();
    const resetQuestionHeight = function(){
        if(isMobile) {
            const threshold = 1;
            const clientHeight = window.innerHeight - threshold;
            $('.block.main').css('height', clientHeight + 'px');
            $('.orientation').css('height', clientHeight + 'px');
        }else{
            $page.find('.main').addClass('pc');
        }
    }
    window.addEventListener('resize', function(event) {
        resetQuestionHeight();
    }, true);
    resetQuestionHeight();


    /////
    // prevent browser history state change
    /////
    window.onpopstate = history.onpushstate = function(e) {
        window.location.reload();
    }

    /////
    // prevent leaving working page
    /////
    // TODO: check if blur is within the same window (skip with opened console)
    // window.addEventListener('blur', function(e){
    //     console.log('FOCUS LOST');
    //     const timeThreshold = 10000;
    //     const focusTimer = setTimeout(() => window.location.reload(), timeThreshold);
    //     const focusFunction = () => {
    //         console.log('FOCUS GAINED!');
    //         console.log('resetting timer');
    //         clearTimeout(focusTimer);
    //         window.removeEventListener('focus', focusFunction);
    //     }
    //     window.addEventListener('focus', focusFunction)
    // });


    /////
    // load current state script
    /////
    if(currentState) {
        fetch(pagesDir + utils.stateToFilename(currentState) + '.html')
            .then(response => response.text())
            .then(text => {
                render(text);
            });
    }


    const render = function (text) {
        const $question = $(".question");
        const $answer = $("#answer");
        const $morph = $(".morph");
        const $record = $(".footer .record");

        toggleBlock('question');

        //check api error messages
        utils.showNotification();

        if(isNewApp){
            $answer.html(text);
            window.questionHeight = $answer.get(0).scrollHeight;
            $answer.height(0);

            intro();
            outro();

            loadMeta();
            progress();
        }else {
            $question.fadeOut(600, () => {
                $question.removeClass('active').removeClass('done');
                $morph.removeClass('active');
                $record.removeClass('active');

                $answer.html(text);

                $question.css('visibility', 'hidden').show(() => {
                    window.questionHeight = $answer.get(0).scrollHeight;
                    $question.css('visibility', 'initial').hide();
                    $answer.height(0);
                });

                loadMeta();
                progress();

                $question.fadeIn(600, () => {
                    intro();
                    outro();
                });

            });
        }

        //check orientation
        if(utils.isMobile()) {
            const $orientation = $('.orientation');
            const checkScreenOrientation = function (matches) {
                if (matches) {
                    //console.log('portrait mode');
                    $orientation.removeClass('active');
                    enableBodyScroll($orientation[0]);
                } else {
                    //console.log('landscape mode');
                    disableBodyScroll($orientation[0]);
                    $orientation.addClass('active');
                }
            };
            let orientation = window.matchMedia("(orientation: portrait)");
            checkScreenOrientation(orientation.matches);
            orientation.addEventListener('change', function (e) {
                checkScreenOrientation(e.matches);
            });
        }
    };


    //check intro modal window
    const intro = function () {
        const $modal = $page.find('.block.intro');

        $modal.find('.button.start').on('click', async function () {
            const micPermission = await initSTT();
            if(micPermission){
                $modal.removeClass('active');
                enableBodyScroll($modal[0]);
                //console.log('closing intro modal');
                await init();
            }else{
                $modal.removeClass('active');
                $page.find('.block.denied').addClass('active');
            }
        });

        $modal.find('.button.next').on('click', function (){
            if (!$(this).hasClass('disabled')) {
                const currentIndex = $modal.find('.state.active').index();
                $modal.find('.state.active').removeClass('active');
                $modal.find('.state').eq(currentIndex+1).addClass('active');
                $modal.find('.bullets span').removeClass('active').eq(currentIndex+1).addClass('active');
            }
        });

        if (null === utils.getFromLS('termsAccepted')) {
            //console.log('need to show intro');
            $modal.find('.agreement').on('change', function () {
                if ($(this).is(':checked')) {
                    $modal.find('.button.accept').removeClass('disabled');
                } else {
                    $modal.find('.button.accept').addClass('disabled');
                }
            });
            disableBodyScroll($modal[0]);
            $modal.addClass('active');
        }
    };

    const init = async function () {
        // const metadata = Bowser.parse(window.navigator.userAgent);
        // const referer = utils.getFromLS('referer');
        //
        // //const uuid = utils.generateUUID();
        // // utils.clearLS();
        utils.setCurrentState(currentState);
        utils.setToLS('termsAccepted', true);
        // utils.setToLS('uuid', uuid);
        window.isOutroShown = true;

        //TODO: update user info to include metadata + inputdevice

        // axios.post(process.env.API_URL + 'api/user/create', {
        //     user: {
        //         uuid: uuid,
        //         source: process.env.SOURCE,
        //         metadata: JSON.stringify(metadata),
        //         inputdevice: await stt.getDeviceName(),
        //         referer: referer
        //     },
        // })
        //     .then(function (response) {
        //         response = response.data;
        //
        //         if (!response.status) {
        //             utils.notifyError(response.message);
        //         }
        //
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });

        await playSpeaker(startLogic);
    };

    const outro = async function (force = false) {
        if (null !== utils.getFromLS('termsAccepted')) {
            const $modal = $page.find('.block.outro');

            $modal.find('.button.start').on('click', async function () {
                const micPermission = await initSTT();
                if(micPermission) {
                    $modal.removeClass('active');
                    enableBodyScroll($modal[0]);
                    //console.log('closing outro modal');
                    await playSpeaker(startLogic);
                }else{
                    $modal.removeClass('active');
                    $page.find('.block.denied').addClass('active');
                }
            });

            if (utils.needToShowOutro() || force) {
                //console.log('need to show outro');
                disableBodyScroll($modal[0]);
                $modal.addClass('active');
            } else {
                //console.log('don\'t need to show outro');
                await playSpeaker(startLogic);
            }
        }
    };

    //load question's title, timer and any other metadata
    const loadMeta = function () {
        const title = meta.titleMobile && utils.isMobileAndTablet() ? meta.titleMobile : meta.title;
        $page.find('.general .title').html(utils.formatTitle(title[0]));
        $page.find('.general .subtitle span').text((_.indexOf(structure.questions, currentState) + 1));
        // meta.time !== 0 ? $page.find('.time').text(meta.time) : $page.find('.time').text('-');
    };

    const progress = function () {
        const totalCount = _.intersection(structure.questions, _.keys(questionsMeta)).length;//count only active questions
        const currentPosition = _.indexOf(structure.questions, currentState) + 1;
        const percent = Math.round(100 / totalCount * currentPosition);

        $page.find('.bar').css('width', percent + '%')
        $page.find('.counter .val').text(currentPosition);
        $page.find('.counter .from').text(totalCount);
    };

    const startLogic = function () {
        questionsScripts[currentState](currentState, stt);
    };

    const playAudioSequence = function (source, callback, index = 0) {

        let listener = () => callback();

        if (!(source instanceof Array)) {
            source = [source];
        }

        // mobile volume trick
        if(index === 0) source.unshift('silence.mp3');

        let file = source[index];
        if (index < source.length - 1) {
            listener = () => setTimeout(() => playAudioSequence(source, callback, index + 1), 1000);
        }

        //Safari iOS volume fade hack
        //https://stackoverflow.com/questions/76083738/ios-safari-lowers-audio-playback-volume-when-mic-is-in-use/
        if(navigator.audioSession){
            //console.log('transient');
            navigator.audioSession.type = 'transient';
        }


        let sound = new Howl({
            src: ['/assets/audio/' + file],
            usingWebAudio: false,
            onplayerror: function (id, error){
                console.log('error');
                console.log(error);
                alert(error);
            }
        });

        sound.play();

        sound.on('end', () =>{
            if(navigator.audioSession){
                navigator.audioSession.type = 'auto';
            }
            listener();
        });

    };

    const initSTT = async function(){
        return await stt.init();
    };

    const playSpeaker = async function (callback) {

        //console.log('playing speaker');

        if (meta.audio) {
            let source = meta.audio;
            if(meta.audioMobile && utils.isMobileAndTablet()) source = meta.audioMobile;
            playAudioSequence(source, callback);
        } else {
            callback();
        }
        // callback();
    };


};

export default questions;